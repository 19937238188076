<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Welcome to DetainEHR! 👋🏻
          </p>
          <p class="mb-2">
            Please sign-in to your account and begin charting.
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-card
            class="mx-auto"
            width="100%"
            max-width="400"
          >
            <v-card-title class="title font-weight-regular justify-space-between">
              <span>{{ currentTitle }}</span>
              <v-avatar
                color="primary lighten-2"
                class="subheading white--text"
                size="24"
                v-text="step"
              ></v-avatar>
            </v-card-title>
            <v-form
              ref="registerForm"
            >
              <v-window v-model="step">
                <v-window-item :value="1">
                  <v-card-text>
                    <v-text-field
                      v-model="email"
                      label="Email"
                      required
                    ></v-text-field>
                    <site-select
                      :site="site"
                      @site-select="updateSite"
                    >
                    </site-select>
                  </v-card-text>
                  <v-spacer>
                  </v-spacer>
                  <router-link
                    to="/login"
                    class="ms-3 mt-1"
                  >
                    Use One-Time Passode?
                  </router-link>
                </v-window-item>

                <v-window-item :value="2">
                  <v-card-text>
                    <v-text-field
                      v-model="code"
                      label="Google Auth App Code"
                      required
                    ></v-text-field>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="3">
                  <div class="pa-4 text-center">
                    <v-img
                      class="mb-4"
                      contain
                      height="128"
                      src="https://www.svgrepo.com/show/43086/success.svg"
                    ></v-img>
                    <h3 class="title font-weight-light mb-2">
                      Welcome to DetainEHR
                    </h3>
                    <span class="caption grey--text">  </span>
                  </div>
                </v-window-item>
              </v-window>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  :disabled="step === 1"
                  text
                  @click="step--"
                >
                  Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="step === 3"
                  color="primary"
                  depressed
                  @click="advance()"
                >
                  Next
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-card-text>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
import SiteSelect from '@/components/site/SiteSelect.vue'
import { getCurrentInstance, onUnmounted } from '@vue/composition-api'
import authenticationService from '@/services/AuthenticationService'
import useAuthenticationService from '@/store/useAuthenticationService'
import store from '@/store'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'

export default {
  components: {
    SiteSelect,
  },
  setup() {
    const MFA_APP = 'app-auth'

    // Register module
    if (!store.hasModule(MFA_APP)) {
      store.registerModule(MFA_APP, authenticationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MFA_APP)) store.unregisterModule(MFA_APP)
    })

    const vm = getCurrentInstance().proxy

    const { router } = useRouter()

    const { mFALogin, parseUserID } = useAuthenticationService()

    const RedirectToDashboard = () => {
      try {
        const userAbility = JSON.parse(localStorage.getItem('userAbility'))
        vm.$ability.update(userAbility)

        router.push('/dashboards/home').catch(() => {})
      } catch (error) {
        alert(`unable to login due to error: ${error}`)
      }
    }

    return {
      mFALogin,
      parseUserID,
      RedirectToDashboard,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
  data: () => ({
    site: null,
    siteId: '',
    step: 1,
    code: '',
    requireMFACode: false,
    otpEmailSent: false,
    instructions: '',
    email: '',
    DTO: {
      siteId: '',
      email: '',
      password: '',
    },
    emailRules: [
      v => !!v || 'Email is required',
      v => v.length >= 5 || 'The e-mail address must contain at least 5 characters',
      v => v.length <= 50 || 'The e-mail address cannot be longer than 50 characters',
      v => /.+@.+/.test(v) || 'Please enter a valid email address',
    ],
    currentTitle: 'Enter your email and site',
  }),
  methods: {
    async advance() {
      console.log('click worked')
      this.step++
      if (this.step === 2) {
        this.DTO.siteId = this.site.id
        this.DTO.email = this.email
        this.DTO.password = ''
      }

      if (this.step === 3) {
        this.DTO.password = this.code
        await this.mFALogin(this.DTO)

        this.RedirectToDashboard()
      }
    },
    updateSite(siteDTO) {
      this.site = siteDTO.value
      this.siteId = siteDTO.value.id
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
